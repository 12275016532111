import './CashiersFinder.scss'

import { GoogleMap, MarkerClusterer } from '@react-google-maps/api'
import { I18nextContext } from 'gatsby-plugin-react-i18next'
import React, { FC, useContext, useEffect, useReducer, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'

import AtmSecondaryIcon from '../../../images/Components/OfficesMap/markerAtmSecondaryIcon.png'
import AtmLkIcon from '../../../images/Components/OfficesMap/markerIcon.png'
import LKApis from '../../../services/lk-apis/LKApisProvider'
import { CashPoint } from '../../../services/lk-apis/types'
import CashierMarker from './CashierMarker'
import PlacesAutocomplete from './PlacesAutocomplete'
import useCashiersUtils, { Cashier } from './useCashiersUtils'

const mapContainerStyle = {
  width: '100%',
  height: '100%',
  minHeight: '400px',
}
const initialZoom = 16
const initialPosition = {
  lat: 43.259353342875926,
  lng: -2.9242048198478874,
}

const markerClustererOptions = {
  imagePath: '/Components/OfficesMap/clusterIcon',
  imageSizes: [65],
  gridSize: 65,
  minimumClusterSize: 5,
}

const uniquePlacesReducer = (_actual: Cashier[], curr: Cashier[] | null): Cashier[] => {
  if (curr === null) {
    return []
  }
  const newItems = curr.filter((e) => !_actual.find((a) => a.place_id === e.place_id))
  return [..._actual, ...newItems]
}

const CashiersFinder: FC = () => {
  const { t } = useTranslation()
  const { language } = useContext(I18nextContext)
  const [loading, setLoading] = useState(false)
  const [map, setMap] = useState<google.maps.Map | null>(null)
  const [center, setCenter] = useState<google.maps.LatLngLiteral>(initialPosition)
  const [selectedCashier, setSelectedCashier] = useState<Cashier>()
  const [cashiers, setCashiers] = useReducer(uniquePlacesReducer, [])
  const { lkWebApi } = useContext(LKApis)
  const { filterCashiers } = useCashiersUtils()

  useEffect(() => {
    const getUserLocation = async () => {
      try {
        await navigator.geolocation.getCurrentPosition(({ coords }) => {
          const { latitude: lat, longitude: lng } = coords
          setCenter({ lat, lng })
        })
      } catch (e) {
        setCenter(initialPosition)
      }
    }
    getUserLocation()
  }, [])

  const handler = useRef(null)

  useEffect(() => {
    setSelectedCashier(null)

    if (handler.current) {
      clearTimeout(handler.current)
    }

    setLoading(true)
    handler.current = setTimeout(async () => {
      if (!center) {
        setLoading(false)
        return
      }

      try {
        const response = await lkWebApi.getCashPointLocations(center.lat, center.lng)
        const places: Cashier[] = response.data?.officeList.map((o: CashPoint) => ({
          place_id: o.placeId,
          name: o.name,
          formatted_address: o.formattedAddress,
          isLk: o.isLK,
          cashierType: 'unknown',
          geometry: {
            location: {
              lat: parseFloat(o?.geometry?.location?.lat),
              lng: parseFloat(o?.geometry?.location?.lng),
            },
          },
        }))
        setCashiers(filterCashiers(places, true))
        setLoading(false)
      } catch (e) {
        console.error('error loading cashiers', e)
        setLoading(false)
      }
    }, 500)
  }, [center, language, lkWebApi])

  return (
    <div className="cashiers">
      <div className="container">
        <div className="cashiers-search">
          <PlacesAutocomplete
            map={map}
            onPlaceSelected={(placeLatLng) => {
              map?.setCenter(placeLatLng)
              map?.setZoom(13)
            }}
          />
        </div>
      </div>

      <div className="map container">
        <GoogleMap
          mapContainerStyle={mapContainerStyle}
          center={initialPosition}
          zoom={initialZoom}
          onLoad={(m: google.maps.Map) => {
            setMap(m)
          }}
          onUnmount={() => {
            setMap(null)
          }}
          onCenterChanged={() => {
            if (map) {
              setCenter(map.getCenter().toJSON())
            }
          }}
          // options={{ disableDefaultUI: true, gestureHandling: 'none' }}
        >
          <MarkerClusterer options={markerClustererOptions}>
            {(cluster) =>
              cashiers.map((place) => (
                <CashierMarker
                  key={place.place_id}
                  place={place}
                  isSelected={place.place_id === selectedCashier?.place_id}
                  onPlaceSelected={(c) => setSelectedCashier(c)}
                  clusterer={cluster}
                />
              ))
            }
          </MarkerClusterer>
        </GoogleMap>
        {loading && (
          <div className="loading">
            <strong>{t('cashiers.loadingCashiers')}</strong>
          </div>
        )}
      </div>
      <div className="legend">
        <span>
          <img src={AtmLkIcon} alt={t('cashiers.typeLk')} /> {t('cashiers.typeLk')}
        </span>
        <span>
          <img src={AtmSecondaryIcon} alt={t('cashiers.typeWhiteList')} />
          <div
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{ __html: t('cashiers.typeWhiteList') }}
          />
        </span>
      </div>
    </div>
  )
}

export default CashiersFinder
